import React, {
  useState,
  useContext,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { useMutation } from 'react-query';
import { message, Upload, Divider } from 'antd';

import { Dashboardcontext } from 'src/pages/dashboard';
import { getUrl, request } from 'src/utils/networkUtils';

const { Dragger } = Upload;

export const UploadFiles = forwardRef((props, ref) => {
  const [files, setFiles] = useState([]);
  const { workspace, setOpen } = useContext(Dashboardcontext);
  const [messageApi, contextHolder] = message.useMessage();
  let full = window.location.host;
  let parts = full.split('.');
  let subdomain = parts[0];

  const Uploadprops = {
    name: 'file',
    multiple: true,
    beforeUpload: (file) => {
      if (file.size / (1024 * 1024) > 20 || file.type !== 'application/pdf') {
        messageApi.open({
          type: 'error',
          content: 'Uploaded file should be a pdf and less than 20Mb',
        });
        return false;
      }
      setFiles((prev) => [...prev, file]);
      return Upload.LIST_IGNORE;
    },
    fileList: files,
    onRemove(file) {
      const finalArr = files.filter((item) => item.uid !== file.uid);
      setFiles((prev) => finalArr);
    },
  };

  const createWorkspaceMediaURL = (data) => {
    const url = getUrl(`v1/workspace/${subdomain}/team/documents`);
    return request('POST', url, data, true, 'multipart/form-data');
  };

  const { mutate: addFiles, isLoading: addFilesLoading } = useMutation(
    createWorkspaceMediaURL,
    {
      mutationKey: 'create-workspace-media',
      onSuccess: (res) => {
        if (res.status === 204) {
          messageApi.open({
            type: 'success',
            content: `Files uploaded successfully`,
          });
          setOpen(false);
          setFiles([]);
          window.location.reload();
        }
      },
      onError: (err) => {
        let reponse = JSON.parse(err.response.data);
        messageApi.open({
          type: 'error',
          content: reponse.detail,
        });
      },
    }
  );

  useImperativeHandle(ref, () => ({
    create() {
      let formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append(`image_${i}`, files[i]);
      }

      addFiles(formData);
    },
  }));

  return (
    <>
      {contextHolder}
      <div className="px-10 py-6 h-[8rem] bg-[#E3E6FD]">
        <div className="text-xl font-semibold">Upload Files</div>
        <div className="flex justify-between my-3">
          <div>
            <div className="text-sm text-[#898884]">Date:</div>
            <div className="text-md font-semibold">19 Jun 2022</div>
          </div>
          <div>
            <div className="text-sm text-[#898884]">Created By</div>
            <div className="text-md font-semibold">Gaurav Kumar</div>
          </div>
        </div>
      </div>
      <div className="px-10 py-4">
        <div className="font-semibold text-lg mb-4">
          Import from your device
        </div>
        <div>
          <Dragger
            {...Uploadprops}
            className="bg-white rounded-lg rounded-xl my-2 kyc-uploader"
          >
            <p className="ant-upload-hint bg-white text-sm text-[#BCBBB8]">
              Drag & Drop files to start uploading
            </p>
            <p className="bg-white mx-auto mt-4 border rounded-xl w-max py-2 px-10">
              <UploadOutlined className="mr-2" />
              Upload Files
            </p>
            <Divider className="my-3 mt-4" />
            <p className="bg-white text-xs text-[#BCBBB8]">in PDF format</p>
          </Dragger>
        </div>
      </div>
    </>
  );
});
