import React, { useContext } from 'react';
import { theme, Layout, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { TEAM } from 'src/utils/constants';

import { Dashboardcontext } from 'src/pages/dashboard';
import { AppContext } from 'src/App';

import { AdminDrawer } from '../drawer/AdminDrawer';

const { Header } = Layout;

export const PrimaryHeader = () => {
  const { open, setOpen, setUploadDocument } = useContext(Dashboardcontext);
  const { header, user } = useContext(AppContext);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <AdminDrawer open={open} setOpen={setOpen} />
      <Header
        style={{ background: colorBgContainer }}
        className="shadow-md flex items-center justify-between px-6 h-[4rem]"
      >
        <div className="text-xl font-semibold flex items-center">
          {location.pathname !== '/' ? (
            <ArrowLeftOutlined onClick={() => navigate(-1)} />
          ) : (
            <></>
          )}
          <div className="ml-2">{header}</div>
        </div>
        <div>
          {parseInt(user.userRole) === TEAM ? (
            <Button
              style={{ width: '10rem' }}
              onClick={() => {
                setOpen(true);
                setUploadDocument(false);
              }}
              className="bg-[#7582F7] w-[10rem] px-4 text-white my-2 w-full rounded-xl primary-button"
            >
              Create workspace
            </Button>
          ) : (
            <Button
              style={{ width: '10rem' }}
              onClick={() => {
                setOpen(true);
                setUploadDocument(true);
              }}
              className="bg-[#7582F7] w-[10rem] px-4 text-white my-2 w-full rounded-xl primary-button"
            >
              Upload Files
            </Button>
          )}
        </div>
      </Header>
    </>
  );
};
