import React, { useState, useContext, useEffect } from 'react';
import {
  InboxOutlined,
  LogoutOutlined,
  ContainerOutlined,
  FileOutlined,
  IdcardOutlined,
  FolderOutlined,
} from '@ant-design/icons';
import { Button, Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';

import { AppContext } from 'src/App';

const { Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

export const SideBar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { logOut, user } = useContext(AppContext);
  const [items, setItems] = useState([false]);
  console.log(user, 'user');
  useEffect(() => {
    let sidebarItems = [
      // getItem(<Link to="/">Inbox</Link>, '1', <InboxOutlined />),
      // getItem(<Link to="/">Recent Cases</Link>, '2', <ContainerOutlined />),
      // getItem(<Link to="/">My Cases</Link>, '3', <FileOutlined />),
    ];

    if (user) {
      if (user.branch.length) {
        sidebarItems.push(getItem('Your Branches', '1'));
      }
      let i = 1;
      user.branch.map((branch, index) => {
        sidebarItems.push(
          getItem(branch.name, `sub${index + 1}`, <IdcardOutlined />, [
            getItem(
              <Link to={`/branch/${branch.id}/visitors`}>
                Visitor Register
              </Link>,
              index + i + 1
            ),
            getItem(
              <Link to={`/branch/${branch.id}/documents`}>Extract Data</Link>,
              index + i + 2
            ),
            getItem(
              <Link to={`/branch/${branch.id}/database`}>Database</Link>,
              index + i + 3
            ),
            getItem(
              <Link to={`/branch/${branch.id}/map`}>Map</Link>,
              index + i + 4
            ),
            // getItem(<Link to="/">Bank Docs</Link>, index + i + 5),
          ])
        );
        i = i + 6;
      });
      // sidebarItems.push(
      //   getItem(<Link to="/">Files</Link>, i + 1, <FolderOutlined />)
      // );
      setItems(sidebarItems);
    }
  }, []);

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      style={{
        background: '#EAE9E4',
      }}
    >
      <div className="flex flex-col justify-between h-full">
        <div>
          <div
            style={{}}
            className="my-6 mx-4 h-[4rem] bg-[#EAE9E4] flex flex-col items-center"
          >
            <div className="text-center">+91-{user.phoneNumber}</div>
            <Button className="bg-[#7582F7] text-white my-2 w-full primary-button">
              New Valuer
            </Button>
          </div>
          <Menu
            className="bg-[#EAE9E4]"
            theme="light"
            defaultSelectedKeys={['1']}
            mode="inline"
            items={items}
          />
        </div>
        <div
          className="w-full text-center items-center justify-center flex hover:cursor-pointer"
          onClick={() => logOut()}
        >
          <div>Log Out</div>
          <LogoutOutlined className="ml-2" />
        </div>
      </div>
    </Sider>
  );
};
