import React, { useContext, forwardRef, useImperativeHandle } from 'react';
import moment from 'moment';
import { Input, InputNumber, message } from 'antd';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { Dashboardcontext } from 'src/pages/dashboard';
import { PHONE_IS_INVALID, INVALID_WORKSPACE } from 'src/utils/error_msgs';
import { getUrl, request, addClientIdToBody } from 'src/utils/networkUtils';

export const CreateWorkspace = forwardRef((props, ref) => {
  const { setOpen } = useContext(Dashboardcontext);

  const date = new Date();
  const data = props.data;
  let navigate = useNavigate();

  const [messageApi, contextHolder] = message.useMessage();

  const createWorkspaceURL = (data) => {
    const url = getUrl('v1/workspace/team');
    return request('POST', url, addClientIdToBody(data), true);
  };

  const { mutate } = useMutation(createWorkspaceURL, {
    onSuccess: (res) => {
      if (res.status === 201) {
        navigate(`/${data.workspaceURL.toLowerCase()}`);
        setOpen(false);
        props.setData({});
      }
    },
    onError: (err) => {
      if (JSON.parse(err.response.data).detail === PHONE_IS_INVALID) {
        messageApi.open({
          type: 'error',
          content: 'Phone number already exists',
        });
      }
      if (JSON.parse(err.response.data).detail === INVALID_WORKSPACE) {
        messageApi.open({
          type: 'error',
          content: 'Workspace URL not available',
        });
      }
    },
  });

  useImperativeHandle(ref, () => ({
    create() {
      if (
        !data.phoneNumber ||
        !data.valuerName ||
        !data.workspaceName ||
        !data.workspaceURL
      ) {
        messageApi.open({
          type: 'error',
          content: 'Some fields are missing',
        });
        return;
      }
      if (data.phoneNumber.toString().length !== 10) {
        messageApi.open({
          type: 'error',
          content: 'Phone Number is invalid',
        });
        return;
      }
      mutate(data);
    },
  }));

  return (
    <>
      {contextHolder}
      <div className="px-10 py-6 h-[8rem] bg-[#E3E6FD]">
        <div className="text-xl font-semibold">New Workspace</div>
        <div className="flex justify-between my-3">
          <div>
            <div className="text-sm text-[#898884]">Date:</div>
            <div className="text-md font-semibold">
              {moment(date).format('Do MMMM YYYY')}
            </div>
          </div>
          <div>
            <div className="text-sm text-[#898884]">Created By</div>
            <div className="text-md font-semibold">Gaurav Kumar</div>
          </div>
        </div>
      </div>
      <div className="px-10 py-4">
        <div className="font-semibold text-lg mb-4">Owner Details</div>
        <div>
          <div className="text-sm text-[#898884]">Phone Number</div>
          <InputNumber
            required
            value={props.data.phoneNumber}
            onChange={(value) =>
              props.setData((prev) => ({
                ...prev,
                phoneNumber: value,
              }))
            }
            placeholder="Enter phone number"
            className="mt-2 w-[23rem] text-md rounded h-[2.5rem] flex items-center input-number"
            min={1}
          />
        </div>
        <div className="my-2">
          <div className="text-sm text-[#898884]">Valuer’s name</div>
          <Input
            onChange={(e) =>
              props.setData((prev) => ({
                ...prev,
                valuerName: e.target.value,
              }))
            }
            value={props.data.valuerName}
            required
            placeholder="Enter valuer's name"
            className="mt-2 w-[23rem] text-md rounded h-[2.5rem] flex items-center input-number"
            min={1}
          />
        </div>
        <div className="font-semibold text-lg my-6">Workspace Details</div>
        <div>
          <div className="text-sm text-[#898884]">Workspace name</div>
          <Input
            value={props.data.workspaceName}
            onChange={(e) =>
              props.setData((prev) => ({
                ...prev,
                workspaceName: e.target.value,
              }))
            }
            required
            placeholder="Enter workspace name"
            className="mt-2 w-[23rem] text-md rounded h-[2.5rem] flex items-center input-number"
            min={1}
          />
        </div>
        <div className="my-2">
          <div className="text-sm text-[#898884]">Workspace url</div>
          <Input
            value={props.data.workspaceURL}
            onChange={(e) =>
              props.setData((prev) => ({
                ...prev,
                workspaceURL: e.target.value,
              }))
            }
            required
            placeholder="Enter workspace url"
            className="mt-2 w-[23rem] text-md rounded h-[2.5rem] flex items-center input-number"
            min={1}
          />
        </div>
      </div>
    </>
  );
});
