import React, { useState, useEffect } from 'react';

import Pagination from 'src/components/pagination';
import { format } from 'date-fns';

export const UserList = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [isSharing, setIsSharing] = useState(false);
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    const accessToken = `${storedUserData.tokens.access}`;
    let apiUrl;
    if (process.env.NODE_ENV === "development") {
        apiUrl = "http://localhost:8000/";
    } else {
        apiUrl = "https://api2.bhume.in/";
    }

    useEffect(() => {
        fetch(apiUrl + `authenticator/get_users?page=${currentPage}`, {
            method: 'GET',
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        })
        .then(response => response.json())
        .then(data => {
            setUsers(data.results);
            // Calculate total pages if needed
            setTotalPages(data.count/25);
            setTotalUsers(data.count);
        })
        .catch(error => console.error('Error:', error));
    }, [currentPage]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    }

    const getMessageTemplate = (item, url) => {
        return `🌟 *New Update Alert from BhuMe!* 🌟 \n\nHello there! 👋 We're excited to bring you the latest version of BhuMe with some incredible new features: \n1. Instant Document Download: Get any document you need in just 30 seconds! 🚀 Yes, it's that fast and efficient. \n2. Assisted Download Service: Need help? Our team will assist you in downloading your documents while you're on a call with us. 📞 It's hassle-free and super convenient!\n\nTo enjoy these amazing services, please update your BhuMe app today. Your next experience with us is just a tap away!\n\n🔗 https://play.google.com/store/apps/details?id=com.currentapp\n\nThank you for being a valued part of our community. We're always working to make BhuMe better for you. 🌈`
      };
      
      const generateWhatsAppMessage = (phonenumber, shortUrl) => {
        return getMessageTemplate(phonenumber, shortUrl);
      };
      
      const handleWhatsAppShare = async (user) => {
        // ... existing code to set the message ...
        if (isSharing) return; // Prevent further execution if already sharing
    
        setIsSharing(true); // Disable the button
        try {
          const playstoreUrl = `https://play.google.com/store/apps/details?id=com.currentapp`;
          
          const whatsappMessage = generateWhatsAppMessage(user.phone_number, playstoreUrl);
          const whatsappUrl = `https://api.whatsapp.com/send?phone=${user.phone_number}&text=${encodeURIComponent(whatsappMessage)}`;
          window.open(whatsappUrl, '_blank');
        } catch (error) {
          console.error('Error during WhatsApp sharing:', error);
          // Handle error
        }
        setIsSharing(false);
      };

    return (
        <div className="bg-gray-100 container mx-auto p-4 w-full">
            <h1 class="text-4xl font-bold text-center mb-4">User List</h1>
            <div>
                Total users: {totalUsers}
            </div>

            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            <table className="w-full table-auto">
                <thead className="bg-gray-50">
                    <tr>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> ID </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> User ID </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Phone Number </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Created At </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Total Requests Raised </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Total Requests Delivered </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Total Credits </th>
                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"> Referred Users </th>
                        {/* ... other table headers ... */}
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {users.map(user => (
                        <tr key={user.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.id}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.userId}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.phone_number}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{format(new Date(user.created_at), 'HH:mm, dd MMM')}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.requests_created_count}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.requests_delivered_count}</td>
                            
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.total_credits_left}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.users_reffered_count}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                <div class="flex">

                                    <a onClick={() => handleWhatsAppShare(user)} disabled={isSharing} style={{ cursor: isSharing ? 'not-allowed' : 'pointer' }} className={`ml-2 ${isSharing ? 'opacity-50' : ''}`}>
                                        <img width="30" height="30" src="https://img.icons8.com/color/48/whatsapp--v1.png" alt="whatsapp"/>
                                    </a>

                                </div>
                            </td>
                            
                            {/* ... other table columns ... */}
                        </tr>
                    ))}
                </tbody>
            </table>
            {/* Pagination controls */}
            <div>
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage <= 1}>Previous</button>
                <span>Page {currentPage}</span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage >= totalPages}>Next</button>
            </div>
        </div>
    );
};
