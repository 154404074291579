import React, { useContext, useState, useRef } from 'react';
import { Drawer } from 'antd';
import { PrimaryButton } from 'src/components/Button';

import { Dashboardcontext } from 'src/pages/dashboard';

import { CreateWorkspace } from './CreateWorkspace';
import { UploadFiles } from './UploadFile';

export const AdminDrawer = ({ open, setOpen }) => {
  const { uploadDocument } = useContext(Dashboardcontext);
  const [data, setData] = useState({
    phoneNumber: '',
    valuerName: '',
    workspaceName: '',
    workspaceURL: '',
  });

  const workspaceRef = useRef();

  return (
    <>
      <Drawer
        placement={'right'}
        width={500}
        onClose={() => setOpen(false)}
        open={open}
        className="rounded-xl admin-drawer p-0"
        closable={false}
        footer={
          <div className="flex justify-center w-full">
            <PrimaryButton
              text="Cancel"
              className="mx-4 rounded-3xl w-[10rem]"
              btnClick={() => setOpen(false)}
            />

            <PrimaryButton
              text={!uploadDocument ? 'Create Workspace' : 'Upload Files'}
              className="w-[10rem] mx-4 text-white primary-button rounded-3xl bg-[#7582F7] hover:bg-[#7582F7]"
              btnClick={() => workspaceRef.current.create()}
            />
          </div>
        }
      >
        {!uploadDocument ? (
          <CreateWorkspace
            data={data}
            setData={setData}
            setOpen={setOpen}
            ref={workspaceRef}
          />
        ) : (
          <UploadFiles ref={workspaceRef} />
        )}
      </Drawer>
    </>
  );
};
