import React, { useContext, useState, useEffect, useRef, createContext } from 'react';

import jwtDecode from "jwt-decode";
import axios from "axios";

import 'react-responsive-carousel/lib/styles/carousel.min.css';

import logo from 'src/images/logo.png';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import SearchDropdown from 'src/components/dropdown';
import RightSide from 'src/components/fetchdata';
import carouselimage1 from 'src/images/1.png';
import carouselimage2 from 'src/images/2.png';
import Pagination from 'src/components/pagination';
import { format } from 'date-fns';

export const Dashboardcontext = createContext({});

function HelloWorld({ userId, tokens, setShowHistory,showHistory }) {
  const accessToken = tokens.access;
  // Create a state variable to store the current page number
  const [page, setPage] = useState(1);
  const authHeader = "Bearer " + accessToken;

  // Create a state variable to store the total number of pages
  const [totalPages, setTotalPages] = useState(0);

  // Create a state variable to store the data from the API
  const [data, setData] = useState([]);
  const [isSharing, setIsSharing] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editValue, setEditValue] = useState('');
  const editInputRef = useRef(null); // Create a ref
  let apiUrl;
  if (process.env.NODE_ENV === "development") {
    apiUrl = "http://localhost:8000/";
  } else {
    apiUrl = "https://api2.bhume.in/";
  }

  // Fetch the data from the API with the page parameter
  useEffect(() => {
    fetch(apiUrl+
      `service/getService`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: authHeader,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: page,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        setData(data.result.data); // Update the data state with the data
        setTotalPages(data.result.total_page); // Update the total pages state with the total_page
                // Do something with the data
        const notification = document.getElementById("notification");
        notification.textContent = JSON.stringify(data.message, null, 0);
        notification.classList.remove("hidden");
        setTimeout(() => {
            notification.classList.add("hidden");
            notification.textContent = "";
        }, 5000); // Message will disappear after 5 seconds
      });
  }, [page]); // Re-run the effect when the page changes

  // Handle the page change event
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber); // Update the page state with the new page number
  };


  const handleFileUpload = (event, srId) => {
    const file = event.target.files[0];
    if (!file) return;
  
    const formData = new FormData();
    formData.append("file", file);
    formData.append("srId", srId.toString());
  
    fetch(`${apiUrl}service/deliver_manual`, {
      method: "POST",
      headers: {
        Authorization: authHeader,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response, e.g., show a success message or handle errors
        if (data && data.success) { // Assuming the API returns a success field
          window.location.reload();
          const notification = document.getElementById("notification");
          notification.textContent = JSON.stringify(data.message, null, 0);
          notification.classList.remove("hidden");
          setTimeout(() => {
              notification.classList.add("hidden");
              notification.textContent = "";
          }, 5000); // Message will disappear after 5 seconds
        } else {
          const notification = document.getElementById("notification");
          notification.textContent = JSON.stringify(data.message, null, 0);
          notification.classList.remove("hidden");
          setTimeout(() => {
              notification.classList.add("hidden");
              notification.textContent = "";
          }, 5000); // Message will disappear after 5 seconds
          // Handle the error, e.g., show an error message
        }
      });
  };
  
  const getMessageTemplate = (item, url) => {
    let prefix;
    if (item.service_name.includes("7/12")) {
      prefix = "Survey no";
    } else if (item.service_name.includes("Akhiv_Patrika")) {
      prefix = "CTS no";
    } else {
      prefix = "khata";
    }
  
    return `नमस्कार, ${prefix} ${item.search_number} के ${item.service_name} डाउनलोड के लिए आपके अनुरोध से 1 दस्तावेज़ प्राप्त हुए। कृपया जांचें। \n\n${url}\n\nधन्यवाद, टीम BhuMe`;
  };
  
  const generateWhatsAppMessage = (item, shortUrl) => {
    return getMessageTemplate(item, shortUrl);
  };

  const shortenUrl = async (url) => {
    try {
      const response = await fetch(apiUrl + 'url/short', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ long_url: url }),
      });
      const data = await response.json();
      if (data.status) {
        return data.result.shorted_url;
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error shortening URL:', error);
      return url; // Fallback to original URL in case of error
    }
  };
  
  const handleWhatsAppShare = async (item) => {
    // ... existing code to set the message ...
    if (isSharing) return; // Prevent further execution if already sharing

    setIsSharing(true); // Disable the button
    try {
      const deliveryUrl = item.service_requests_history[item.service_requests_history.length - 1].delivery_url;
      const fileId = deliveryUrl.substring(deliveryUrl.lastIndexOf('/') + 1);
      const newDeliveryUrl = `https://currentbhume.page.link?utm_campaign=banner&link=https://delivery.bhume.in/files/${fileId}`;
      
      const shortUrl = await shortenUrl(newDeliveryUrl);
      const whatsappMessage = generateWhatsAppMessage(item, shortUrl);
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${item.created_by.phone_number}&text=${encodeURIComponent(whatsappMessage)}`;
      window.open(whatsappUrl, '_blank');
    } catch (error) {
      console.error('Error during WhatsApp sharing:', error);
      // Handle error
    }
    setIsSharing(false);
  };

  const handleRetry = (srId) => {
    fetch(`${apiUrl}service/retry-service-request/`, {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: authHeader, // Assuming you want to use the same auth header
        },
        body: JSON.stringify({
            srId: srId
        }),
    })
    .then((response) => response.json())
    .then((data) => {
        // Handle the response, e.g., show a success message or handle errors
        const notification = document.getElementById("notification");
        notification.textContent = JSON.stringify(data.message, null, 0);
        notification.classList.remove("hidden");
        setTimeout(() => {
            notification.classList.add("hidden");
            notification.textContent = "";
        }, 5000); // Message will disappear after 5 seconds
    })
    .catch((error) => {
        console.error("Error:", error);
        // Handle the error, e.g., show an error message
    });
  };
    // Function to handle edit click
  const handleEditClick = (item) => {
    setEditingItemId(item.id);
    setEditValue(item.search_number);
    // Focus on the input after the next render
    setTimeout(() => {
      if (editInputRef.current) {
        editInputRef.current.focus();
      }
    }, 0);
  };
  const handleSubmitEdit = async (srId) => {
    // API request to update search number
    try {
      const response = await fetch(apiUrl + 'service/edit-service-request/', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: authHeader,
        },
        body: JSON.stringify({
          srId,
          searchNo: editValue,
        }),
      });
      const responseData = await response.json();
      if (response.ok) {
        // Update the specific item in the data state
        setData(prevData => prevData.map(item => 
          item.id === srId ? { ...item, search_number: editValue } : item
        ));
  
        setEditingItemId(null); // Reset editing state
         // Show success notification
        const notification = document.getElementById("notification");
        notification.textContent = "Edit successful: " + JSON.stringify(responseData.message, null, 0);
        notification.classList.remove("hidden");
        setTimeout(() => {
          notification.classList.add("hidden");
          notification.textContent = "";
        }, 5000); // Message will disappear after 5 seconds
  
        // Optionally, you can show a success message here
      } else {
        // Handle API error response
        const notification = document.getElementById("notification");
        notification.textContent = "Error: " + JSON.stringify(responseData.message, null, 0);
        notification.classList.remove("hidden");
        setTimeout(() => {
          notification.classList.add("hidden");
          notification.textContent = "";
        }, 5000); // Message will disappear after 5 seconds
      }
    } catch (error) {
      // Handle the error, e.g., show an error message
      const notification = document.getElementById("notification");
      notification.textContent = "Exception Error: " + error.toString();
      notification.classList.remove("hidden");
      setTimeout(() => {
        notification.classList.add("hidden");
        notification.textContent = "";
      }, 5000); // Message will disappear after 5 seconds
    }
    
  };
  const handleDiscardEdit = () => {
    setEditingItemId(null);
  };
  const handleKeyDown = (itemId, e) => {
    if (e.key === 'Escape') {
      // Discard the input (You can reset editValue or call handleDiscardEdit)
      handleDiscardEdit();
    } else if (e.key === 'Enter') {
      // Submit the form
      handleSubmitEdit(itemId);
    }
  };

  // Render the data in a table
  return (
    // <div className="bg-gray-100 container mx-auto p-4 w-full">
    <div className="bg-gray-100 p-4" style={{ height: '100vh', width: '100vw' }}>
      <h1 class="text-4xl font-bold text-center mb-4">Service Requests</h1>
            {/* Render the pagination component */}
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            

      <table className="w-full table-auto">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              ID
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Request Time
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Service Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Search Number
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Village
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Phone
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Request Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Payment Status
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Download
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((item) => (
            // Iterate over the data array
            <tr key={item.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {item.id}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {format(new Date(item.request_date_time), 'HH:mm, dd MMM')}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {item.service_name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {editingItemId === item.id ? (
                  <div className="flex items-center">
                    <input 
                      ref={editInputRef}
                      type="text"
                      value={editValue}
                      onChange={(e) => setEditValue(e.target.value)}
                      onKeyDown={(e) => handleKeyDown(item.id, e)} 
                      className="text-sm border border-gray rounded-full px-2"
                      style={{ width: '50px', height: '30px' }} // Set the width here
                    />
                    <button onClick={() => handleSubmitEdit(item.id)} className="border border-gray-300 hover:bg-green-400 hover:border-white text-white font-bold py-1 px-2 rounded-full ml-2">
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 30 30"> <path d="M 26.980469 5.9902344 A 1.0001 1.0001 0 0 0 26.292969 6.2929688 L 11 21.585938 L 4.7070312 15.292969 A 1.0001 1.0001 0 1 0 3.2929688 16.707031 L 10.292969 23.707031 A 1.0001 1.0001 0 0 0 11.707031 23.707031 L 27.707031 7.7070312 A 1.0001 1.0001 0 0 0 26.980469 5.9902344 z"></path> </svg>
                    </button> 
                    <button onClick={handleDiscardEdit} className="border border-gray-300 hover:bg-red-400 hover:border-white text-white font-bold py-1 px-2 rounded-full ml-2" style={{ height: '30px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="15" height="15" viewBox="0 0 50 50">  <path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"></path></svg>
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center">
                    {item.search_number}
                    {(item.request_status === "Pending" || item.request_status === "Failed" || item.request_status === "New") && (
                    <button onClick={() => handleEditClick(item)} className="hover:bg-gray-200 text-white font-bold py-1 px-2 rounded-full ml-2">
                      <img src="https://img.icons8.com/ios/50/000000/pencil--v1.png" alt="Edit" width="16"/>
                    </button>
                   
                    )}
                  </div>
                )}
              </td>
              {
                  item.village ? (
                    <>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.village.village_text_en}
                        <br/>
                        {item.village.id}
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                    </>
                  )
                }

              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {item.created_by.phone_number}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {item.request_status}
                {item.request_status === "Failed" || item.request_status === "New" || item.request_status === "Pending" ? (
                <>
                <br/>
                {item.failed_reason}
                </>) : (
                  ''
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                
                {item.is_paid === false  ? (
                <> 
                <p>Not Paid
                <br/>
                {item.service_amount_to_collect}
                </p>
                </>) : (
                  <p>Paid
                  <br/>
                  {item.service_amount_to_collect}
                  </p>
                )}

              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
              {item.request_status === "Failed" || item.request_status === "New" || item.request_status === "Pending" ? (
                  <>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => handleFileUpload(e, item.id)}
                      id={`file-input-${item.id}`}
                    />
                    <button onClick={() => document.getElementById(`file-input-${item.id}`).click()}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                      Upload File
                    </button>
                    {/* Retry Button */}
                    <button 
                        onClick={() => handleRetry(item.id)}
                        className="ml-2 bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                    >
                        Retry
                    </button>
                  </>
                ) : (
                  // ... your existing code for the download link ...
                  ''
                )}
                {item.service_requests_history && item.service_requests_history.length > 0 ? (
                  <div class="flex">
                  <a href={item.service_requests_history[item.service_requests_history.length - 1].delivery_url} target="_blank" rel="noopener noreferrer">
                    <img width="30" height="30" src="https://img.icons8.com/ios-glyphs/30/download--v1.png" alt="download--v1"/>
                  </a>
                  {/* WhatsApp Share Button */}
                  <a onClick={() => handleWhatsAppShare(item)} disabled={isSharing} style={{ cursor: isSharing ? 'not-allowed' : 'pointer' }} className={`ml-2 ${isSharing ? 'opacity-50' : ''}`}>
                    <img width="30" height="30" src="https://img.icons8.com/color/48/whatsapp--v1.png" alt="whatsapp"/>
                  </a>
                  
                  </div>
                ) : (
                  ''
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export const DashBoard = () => {
  const [userData, setUserData] = useState(null); 
  const [showHistory, setShowHistory] = useState(); // A state to track if the user is logged in or not
  const [loggedIn, setLoggedIn] = useState(false); // A state to track if the user is logged in or not
  const [phoneNumber, setPhoneNumber] = useState(""); // A state to store the phone number entered by the user on the login screen
  const [otpSent, setOtpSent] = useState(false); // A state to track if the otp has been sent or not

  useEffect(() => {
    console.log(userData);
    // If there is any user data, store it in the local storage
    if (userData) {
      localStorage.setItem("userData", JSON.stringify(userData));
    }
    
  }, [userData]);

  // Define a function that checks if the access token is expired
  function isTokenExpired(token) {
    // Decode the token and get its expiration date
    const decodedToken = jwtDecode(token);
    console.log("istoken func called");
    const expirationDate = new Date(decodedToken.exp * 1000);
    // Compare it with the current date
    const currentDate = new Date();
    // Return true if expired, false if not
    return expirationDate < currentDate;
  }
  // Define a function that refreshes the access token
  async function refreshfunc(refresh) {
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    let apiUrl;
    if (process.env.NODE_ENV === "development") {
      apiUrl = "http://localhost:8000/";
    } else {
      apiUrl = "https://api2.bhume.in/";
    }
    axios
    .post(apiUrl+
      "authenticator/refresh",
      
      { refresh: refresh },
      { headers: { "Content-Type": "application/json" } }
    )
    .then((response) => {
      // If successful, set the logged in and user data state
      console.log("api ne sahi respond kiya");
      const newUserData = {
        ...storedUserData,
        tokens: { ...storedUserData.tokens, access: response.data.access },
      };
      setLoggedIn(true);
      setUserData(newUserData);
      localStorage.setItem("userData", JSON.stringify(newUserData));
    })
    .catch((error) => {
      // If error, display the error message
      console.error(error);
      setLoggedIn(false);
      setUserData(null);
      console.log("redirecting to login page")
    });

  }
  

  // Define a useEffect hook that runs when the app renders
  useEffect(() => {
    // Get the user data from the local storage
    const storedUserData = JSON.parse(localStorage.getItem("userData"));
    // If there is any user data, check if the access token is expired
    if (storedUserData) {
      const accessToken = storedUserData.tokens.access;
      const refreshToken = storedUserData.tokens.refresh;

      console.log(accessToken);
      if (isTokenExpired(accessToken)) {
        // If it is expired, refresh it and update the user data state and local storage
        console.log("token had expired")
        refreshfunc(refreshToken)
        // setLoggedIn(false);
        // setUserData(null);
      } else {
        // If it is not expired, set the logged in and user data state
        setLoggedIn(true);
        console.log("token not expired")
        setUserData(storedUserData);
      }
    }
  }, []);

  return (
    <div class="flex justify-center h-screen">
    <div id="notification" class="hidden fixed bottom-5 left-0 right-0 mx-auto w-96 bg-green-500 text-white p-4 rounded-lg text-center shadow-lg transform transition-opacity duration-300">
    
    </div>
		<div class="flex mx-auto grid grid-cols-12 gap-0 w-screen" style={{ margin: '0 0' }}>
		{/* <!-- left side --> */}
			{loggedIn ? (
        showHistory ? (null):(
			// If the user is logged in, display the hello world screen with the user data
			<div className="col-span-12 md:col-span-12 ">
      <HelloWorld userId={userData.userId} tokens={userData.tokens} showHistory={showHistory} setShowHistory={setShowHistory} />
      </div>
      )
			) : otpSent ? (
			// If the otp has been sent, display the otp screen with the phone number
			<div class="bg-gray-100 left-side col-span-12 md:col-span-4 h-screen sticky top-0">
				<Otp
				phoneNumber={phoneNumber}
				setLoggedIn={setLoggedIn}
				setUserData={setUserData}
				/>
			</div>
			) : (
			// If neither, display the login screen with the set phone number and otp sent functions
			<div class="bg-gray-100 left-side col-span-12 md:col-span-4 h-screen sticky top-0">
				<Login setPhoneNumber={setPhoneNumber} setOtpSent={setOtpSent} />
			</div>
			)}

		
	
	  </div>
  </div>
  );
};




// A component for the login screen
function Login({ setPhoneNumber, setOtpSent,otpSent}) {
  const [dialCode, setDialCode] = useState(91);
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");

  // A function to handle the submit button click
  function handleSubmit(e) {
    e.preventDefault();
    // Validate the phone number
    if (phone.length !== 10 || isNaN(phone)) {
      setError("Please enter a valid 10-digit phone number");
      return;
    }
    let apiUrl;
    if (process.env.NODE_ENV === "development") {
      apiUrl = "http://localhost:8000/";
    } else {
      apiUrl = "https://api2.bhume.in/";
    }
    // Call the generateotp API
    axios
      .post(apiUrl+"authenticator/generate_otp", {
          
      dial_code: "91",
        phone_number: phone,
      })
      .then((response) => {
        // If successful, set the phone number and otp sent state
        setPhoneNumber(`${dialCode}${phone}`);
        setOtpSent(true);
        console.log(otpSent,setOtpSent);

      })
      .catch((error) => {
        // If error, display the error message
        setError(error.response.data.message);
      });
  }

  return (
    <div class="flex items-center justify-center h-screen" >
      <div class="w-full max-w-xs">
        <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
          <img src={logo} alt="logo" className="my-4 w-12 h-auto"/>
          <h1 class="text-2xl font-bold mb-4">Login</h1>
          <div class="mb-6">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="phone">Phone number:</label>
            <input
              class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="phone"
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          <div class="flex items-center justify-between">
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
        {error && <p class="text-red-500 text-xs italic">{error}</p>}
      </div>
    </div>
  );
}

// A component for the otp screen
function Otp({ phoneNumber, setLoggedIn, setUserData }) {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");

  // A function to handle the submit button click
  function handleSubmit(e) {
    e.preventDefault();
    // Validate the otp
    if (otp.length !== 6 || isNaN(otp)) {
      setError("Please enter a valid 6-digit otp");
      return;
    }
    let apiUrl;
    if (process.env.NODE_ENV === "development") {
      apiUrl = "http://localhost:8000/";
    } else {
      apiUrl = "https://api2.bhume.in/";
    }
    // Call the verifyOtp API
    axios
      .post(apiUrl+"authenticator/verify_otp", {
        dial_code: phoneNumber.slice(0, 2),
        phone_number: phoneNumber.slice(2),
        otp: otp,
      })
      .then((response) => {
        if (response.data.message === "Failed to Verify Otp") {
          setError("Failed to verify OTP. Please enter the correct OTP.");
          setOtp(""); // Clear the OTP input field
        } else {
          // If successful, set the logged in and user data state
          setLoggedIn(true);
          setUserData(response.data.result);
        }
      })
      .catch((error) => {
        // If error, display the error message
        setError(error.response.data.message);
      });
  }

  return (
    <div class="flex items-center justify-center h-screen">
      <div class="w-full max-w-xs">
        <form class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4" onSubmit={handleSubmit}>
          <img src={logo} alt="logo" className="my-4 w-12 h-auto"/>
          <h1 class="text-2xl font-bold mb-4">Enter OTP</h1>
          <p class="text-gray-700 mb-4">We have sent an OTP to {phoneNumber}</p>
          <div class="mb-6">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="otp">OTP:</label>
            <input
              class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="otp"
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
          </div>
          {error && <p class="text-red-500 text-xs italic">{error}</p>}
          <div class="flex items-center justify-between">
            <button
              class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Verify OTP
            </button>

          </div>
        </form>
      </div>
    </div>
  );
}
